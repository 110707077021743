.reviews {
  padding-bottom: var(--sectionPadd);
}
.reviews .review {
  margin: 0 10px;
  padding: 20px;
  border-radius: 5px;
}
.reviews .review_slider {
  margin-top: 40px;
}
.reviews .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}
.reviews .review img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Mobile */
.reviews.sm {
  padding-bottom: 0;
}
.reviews.sm .review {
  height: 100%;
}
