.home .slider__slide-heading {
  font-weight: 900;
  position: relative;
}
.home::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.1); */
  z-index: 11;
}
.home .slider__slide-readmore {
  background-color: var(--green);
  color: #fff;
  border: 0;
  outline: 0;
  padding: 15px 30px;
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 5px;
  font-size: 1rem;
  text-decoration: none;
}

/* mobile */

.home.sm .slider.s--ready .slider__control {
  display: none;
}
.home.sm .slider__slide-heading {
  font-size: 2.5rem;
}
.home.sm .slider__slide-content {
  padding: 0 20px;
  align-items: baseline;
}
