.branches{
    padding: var(--sectionPadd) 0;
}
.branches .branch{
    background-color: #f1f5f6;
    height: 100%;
    padding: 15px 0 20px 15px;
}
.branches .branch .no{
    font-family: Arial, Helvetica, sans-serif !important;    
}
.branches .branch h4{
    color: var(--green);
    font-weight: 700;
}
.branches .branch{
    color: var(--font-light);
}
.branches .branch a{
    color: var(--green);
    font-weight: 600;
    text-decoration: none;
    border: 2px solid var(--green);
    padding: 8px 24px;
    border-radius: 4px;
}
.branches .branch a:hover{
    background-color: var(--green);
    color: #fff;
    transition: 0.2s;
}

/* mobile */

.branches.sm{
    padding-top: 0;
}