.services {
  padding: var(--sectionPadd) 0;
  overflow-x: hidden;
}
.services .container {
  width: 100%;
  min-height: 10vh;
  position: relative;
}
.services .container .bg-rotate,
.services .container .view_service {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.services .container .view_service {
  background-color: var(--green);
  color: #fff;
  font-weight: 700;
  text-decoration: none;
  font-size: 1.1rem;
  padding: 15px 30px;
  border-radius: 5px;
}
.services .container .bg-rotate img {
  width: 500px;
  animation: spin infinite 50s linear;
  opacity: 0.9;
}
.services .title_bar {
  width: 100%;
  justify-content: center;
}
.services .sub_title {
  text-align: center;
}
/* mobile */

.services.sm{
  padding-bottom: 0;
}
.services.sm .container .bg-rotate {
  margin-top: 50%;
  position: relative;
  text-align: center;
}
.services.sm .container .view_service {
  width: 100%;
  text-align: center;
}
