 .photo {
    position: relative;
  }
 .photo img {
    margin-top: 30px;
    border-radius: 20px;
  }
 .photo h5 {
    content: "";
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(5px);
    position: absolute;
    bottom: 0;
    right: 0;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    margin: 0;
    color: #fff;
    text-align: center;
    padding: 20px 0;
    font-weight: 700;
  }
 .photo .viewall {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
 .photo h5 a {
    background-color: var(--blue);
    color: #fff;
    text-decoration: none;
    padding: 10px 30px;
    font-size: 1rem;
    border-radius: 5px;
    margin-left: 10px;
  }
  