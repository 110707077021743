.footer {
  background-color: var(--blue);
  padding: 40px 0 calc(var(--sectionPadd) + 40px) 0;
}
.footer .content h2,
.footer .r2 h2,
.footer .r3 h2 {
  color: #fff;
  font-weight: 700;
  text-align: center;
}
.footer .content p {
  text-align: justify;
  color: #fff;
  font-weight: 500;
}
.footer .r2 .content a {
  color: #fff;
  font-weight: 700;
  text-decoration: none;
  padding: 5px 0;
  display: block;
}
.footer .content h6 {
  color: #fff;
  font-weight: 500;
  text-align: center;
}
.box {
  background-color: #fff;
  height: 100%;
  margin-top: -50px;
  padding: 1rem;
}
.box .row {
  align-items: center;
}
.box h4 {
  font-style: italic;
  color: #000;
  margin-left: 20px;
  font-weight: 600;
}
.box h3 {
  font-weight: bold;
  color: #000;
  margin-left: 20px;
  font-weight: 800;
}
.box a {
  background-color: var(--green);
  color: #fff;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  padding: 13px 35px;
  display: block;
  text-align: center;
  font-size: 1.1rem;
}
.box a:hover {
  color: #fff;
}
.brand {
  width: 150px;
}
