.pledge {
  background-color: rgba(214, 229, 250, 0.3);
  padding: var(--sectionPadd) 0;
  overflow-x: hidden;
}
.pledge .pledge_slider {
  width: 100%;
  height: 500px;
}
.pledge .pledge_slider img {
  object-fit: fill;
  height: 100%;
  display: block;
  width: 100%;
}
.pledge .content {
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: -150px;
  transform: translateY(-50%);
  padding: 40px 30px;
  border-radius: 10px;
}
.pledge .content p {
  color: var(--font-dark);
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.8;
  text-align: justify;
}

/* mobile */

.pledge.sm .container {
  padding: 0;
  overflow-x: hidden;
}
.pledge.sm .pledge_slider {
  height: 300px;
}
.pledge.sm.md .pledge_slider {
  height: 500px !important;
}
.pledge.sm .pledge_slider img {
  margin: 0 auto;
  width: 100vh;
}
.pledge.sm .content {
  position: relative;
  left: 0;
  padding: 30px 15px;
  margin-top: 30px;
}
