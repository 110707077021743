.service {
  background-color: var(--green-light);
  backdrop-filter: blur(5px);
  border-radius: 10px;
  margin-top: 40px;
  padding-bottom: 20px;
}
.service img {
  height: 280px;
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.service .info {
  padding: 10px;
}
.service h4 {
  font-weight: 700;
  margin-bottom: 30px;
  color: #333;
}
.service a {
  color: #fff;
  text-decoration: none;
  background-color: var(--blue);
  font-weight: 600;
  padding: 15px 30px;
  border-radius: 5px;
}
.service a:hover {
  color: #fff;
}
.service.sm {
  margin-top: 10px;
}
.service.sm img {
  height: 230px;
}
