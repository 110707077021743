.top_nav_lg,
.top_nav_sm {
  background-color: rgba(255, 255, 255, 0.3);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1111;
  padding: 10px 0;
  backdrop-filter: blur(5px);
}
.top_nav_lg .container-fluid {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.top_nav_lg img {
  border-radius: 10px;
  width: 100px;
}
.top_nav_lg .nav_links a {
  text-decoration: none;
  color: #fff;
  background-color: var(--orange);
  padding: 10px 25px !important;
  margin-right: 10px;
  font-weight: 600;
  border-radius: 6px;
}
.top_nav_lg .nav_links a.btn {
  background-color: var(--blue);
  padding: 8px 25px !important;
  border: 0;
}

/* mobile */
.top_nav_sm {
  padding: 10px;
}
.top_nav_sm .content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.top_nav_sm .content img {
  width: 80px;
}
.top_nav_sm .content span {
  background-color: var(--orange);
  font-size: 2.2rem;
  display: flex;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}
.top_nav_sm .links {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  left: -100%;
  top: var(--topPaddingSm);
  transition: 0.2s;
}
.top_nav_sm .links a {
  text-decoration: none;
  color: #333;
  font-weight: 600;
  margin: 5px 10px;
  font-size: 1.1rem;
}
.top_nav_sm .links a.btn {
  background-color: var(--blue);
  color: #fff;
  margin: 10px !important;
}
.top_nav_sm .links.active {
  left: 0;
  transition: 0.2s;
}
