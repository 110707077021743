.contact {
  padding: var(--sectionPadd) 0;
}
.contact .map iframe {
  width: 100%;
  height: 50vh;
  margin-top: 30px;
}
.contact .contact_form {
  width: 60%;
  margin: 10px auto;
}
.contact .contact_form h5 {
  margin: 20px;
  text-align: center;
}
.contact .contact_form input,
.contact .contact_form textarea {
  background-color: #eee;
  padding: 18px 20px;
  border: 0;
}
.contact .contact_form button {
  margin-top: 15px;
  border: 2px solid var(--blue);
  color: var(--blue);
  font-weight: 600;
  padding: 15px 40px;
  border-radius: 5px;
  background-color: transparent;
  transition: 0.2s;
}
.contact .contact_form button:hover {
  background-color: var(--blue);
  transition: 0.2s;
  color: #fff;
}
.contact .contact_form input:focus,
.contact .contact_form textarea:focus {
  box-shadow: 0 0 0;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


/* Mobile */

.contact.sm .contact_form{
  width: 100%;
}
.contact.sm .map iframe {
  height: 40vh;

}
.contact.sm .contact_form h5{
  font-size: 1rem;
}