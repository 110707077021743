.banner{
    width: 100%;
    background-image: url("../img/about-banner.jpg"); 
    padding: var(--sectionPadd) 0;
    text-align: center;
    color: #fff;
    background-position: 100% 100%;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}
.banner h1{
    padding: calc(var(--sectionPadd) + var(--sectionPadd)) 0;
    font-weight: 900;
    font-size: 3rem;
}